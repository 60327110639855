.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

header .title {
  font-weight: 700;
  font-size: 36px;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  text-align: center;
  position: inline;
  left: 0;
  right: 0;
  pointer-events: none;
}